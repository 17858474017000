* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
  -webkit-user-drag: none;
}


html {
  scroll-behavior: smooth;
  background-color: #0D0D0D;
}

body {
  background-color: #0D0D0D;
  overflow-X: hidden;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #000;
}

body::-webkit-scrollbar-thumb {
  background-color: #000;

  background-image: -webkit-gradient(linear, 0 0, 0 100%,
      color-stop(.5, rgba(255, 255, 255, .2)),
      color-stop(.5, transparent), to(transparent));
}

svg {
  display: inline !important;
}

/* Hero */

.hero {
  /* min-height: 111vh; */
  overflow: hidden;
  position: relative;
}

.heroWrapper {
  background-color: #EEE3D3;
  color: #0D0D0D;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100vw;
  /* min-height: 105vh; */
}

.heroClipper {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: #0D0D0D;
  color: #EEE3D3;
  width: 100%;
  /* min-height: 105vh; */
  z-index: 2;
}

.heroWrapper.clip {
  background-color: #0D0D0D;
  color: #EEE3D3;
}

.motionBox {
  background-color: #0D0D0D;
  color: #EEE3D3;
}

.motionContainer {
  width: 30%;
  border-radius: 30px;
  background-color: #121212;
  padding: 1rem;
  margin-top: 2rem;
  position: relative;
}

.videoContainer {
  width: 48%;
  padding-bottom: 1rem;
  border-radius: 30px;
  background-color: #121212;
  margin-top: 2rem;
  overflow: hidden;
}

.videoFlex video {
  height: 32rem !important;
}

.videoFlex {
  align-items: center;
  padding-top: 6rem;
}

.bigVideoCont {
  width: 100%;
  border-radius: 30px;
  padding-bottom: 1rem;
  background-color: #121212;
  margin-top: 2rem;
  overflow: hidden;
}

.videoContainer video {
  width: 100%;
  height: 18rem;
}


.motionContainer>div>svg {
  width: 75% !important;
  height: 14rem !important;
}

.motionContainer>div:first-child {
  display: flex;
  justify-content: center;
}

.lottieContainer>div:first-child {
  display: flex;
  height: 2.6rem;
  justify-content: left;
  transform: translateX(-1.6rem);
}

.greet>div:first-child {
  display: flex;
  height: 3rem;
  width: 3rem;
  background-color: #EEE3D3;
  border-radius: 50%;
  margin-right: 0.4rem;
  justify-content: left;
  transform: translateX(-0.4rem);
}

.titleHeading {
  font-size: 1.4rem;
  margin-left: 0.4rem;
  font-weight: 600;
}
.progress{
  position: fixed;
  height: 2px;
  background-color: #EEE3D3;
  transition: 0.1s ease;
  width: 0;
  top:0;
}

.heroVideo{
  mix-blend-mode: revert;
}

.navbar {
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-inline: auto;
  padding-top: 2.25rem;
}
.navbar.studies{
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-inline: auto;
  padding-top: 2.25rem;
}
.ham.studies{
  margin-left: -4rem;
  z-index: 3;
}
.arrowHead{
  width: 5rem;
  justify-content: center;
  align-items: center;
  height: 5rem;
  border-radius: 50%;
  border: 3px solid #EEE3D3;
}

.greet {
  background-color: #232323;
  align-items: center;
  border-radius: 40px;
  padding-inline: 1rem;
  padding-block: 0.3rem;
}
.greet.studies{
  width: 3rem;
  cursor: pointer;
  height: 3rem;
}
.greet.studies>img{
  max-width: 130%;
  margin-left: -0.1rem;
}
.ham {
  background: #232323;
  border-radius: 50%;
  padding: 1rem;
  position: relative;
  cursor: pointer;
}

.hams {
  width: 16px;
  height: 1px;
  border-radius: 1.2rem;
  margin-top: 0.2rem;
  background: #EEE3D3;
  transition: transform 0.3s ease;
  border: 0.5px solid #EEE3D3;
}

.menu {
  position: absolute;
  top: 4rem;
  right: 1rem;
  padding: 0.4rem;
  border-radius: 1rem;
  transition: all 0.3s ease-in;
  background-color: #373737;
  width: 11rem;
  color: rgb(237, 237, 237);
}

.menu>a {
  border-radius: 0.5rem;
  text-decoration: none;
  /* padding-right: 2rem; */
  width: 100%;
  display: block;

  padding-left: 0.7rem;
  padding-block: 0.6rem;
  transition: all 0.1s ease-in;
}
.link{
  text-decoration: none;
  background-color: #474747;
}

.menu>a:hover {
  text-decoration: none;
  background-color: #707070;
}

.one {
  transform: translateY(-100%);
}

.three {
  transform: translateY(100%);
}

.heading {
  font-weight: 600;
  font-size: 4rem;
  justify-content: center;
  align-items: center;
  margin-top: 2.25rem;
}

.heading.motion {
  margin-top: 0;
  padding-top: 2.25rem;
}

.motionContainer>svg {
  position: absolute;
  right: 20px;
  opacity: 80%;
  top: 16px;
  width: 2.6rem;
}

.subHeading {
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-inline: auto;
  font-size: 1.6rem;
  height: 4rem;
  text-align: center;
  margin-top: 3vw;
}

.slick-slider {
  /* margin-block: 3rem; */
  /* animation: marquee 80s infinite; */
}
.slick-dots{
  bottom: 20px !important;
  z-index: 3;
  right: -150px !important;
}
.slick-dots>li{
  margin: 0 !important;
}
.slick-dots li button:before{
    color: #EEE3D3 !important;
   font-size: 0.8rem !important;
}
.slick-prev, .slick-next{
  z-index: 2;
  top: 50% !important;
  height: 95% !important;
  width: 20% !important;
}
.slick-arrow{
  transition: all 0.3s ease;
  border-radius: 24px;
}
.slick-arrow:hover{
  background-color: #eee3d306 !important;
  box-shadow: -10px 0px 5px 10px #eee3d306;
  filter: blur(2px);
  cursor: e-resize;
}
.slick-prev:hover{
  box-shadow: 10px 0px 5px 10px #eee3d306;
  cursor: w-resize;
}
.slick-arrow::before{
  content: '' !important;
}
.slick-prev{
  left: 0 !important;
}
.slick-next{
  right: 0 !important;
}


.heroCarousel {
  justify-content: center;
  transition: all 2s linear;
}

.caroItem {
  width: 23rem;
  /* flex-shrink: 0; */
  border-radius: 24px;
  margin-inline: 1%;
  height: 24rem;
  transition: all 0.3s ease;
}

.caroItem svg {
  position: absolute;
  top: -65px;
  transform: rotate(-20deg) !important;
  width: 10rem !important;
  height: 10rem !important;
  left: -2rem;
  fill: transparent;
  stroke: black;
  stroke-width: 3;
  opacity: 1;
  stroke-dasharray: 4000;
  transition: 0.9s ease-out;
}

.caroItem.inactive {
  filter: grayscale();
  opacity: 10%;
}

.inactive {
  opacity: 10%;
}

.testimonial {
  width: 40%;
  overflow-x: hidden;
  transition: all 0.4s ease-out;
}

.testWrapper {
  transition: all 0.4s ease-out;
}

/* Case Study Page */

.container {
  background-color: #0b0b0b;
  color: #EEE3D3;
  position: relative;  /* padding-left: 3rem; */
  /* padding-bottom: 3rem; */
}

.caseHead {
  padding-top: 0rem;
  width: 80%;
  padding-left: 5rem;
  font-weight: 700;
  font-size: 3rem;
}

.caseInfo {
  margin-top: 5rem;
  padding-left: 5rem;
  width: 90%;
  padding-bottom: 8rem;
}
.caseInfo.cases{
  margin-top: 0;
}

.head {
  font-weight: 500;
  font-size: 1.2rem;
  padding-left: 0.35rem;
}
.head.studies{
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.04rem;

}

.name {
  font-size: 1.6rem;
}
.name.feature{
  font-weight: 500;
  font-size: 1.8rem;
}
.name.feature.right{
  justify-content: space-between;
}

.name.study{
  font-size: 2.5rem;
  padding-left: 0.35rem;
  font-weight: 500;
}

.aboutVision {
  margin-top: 3rem;
}
.about{
  width: 300%;
}
.roundVideo{
  margin-top: 2rem;
  border-radius: 50px;
}

.info {
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  width: 90%;
  font-size: 1.15rem;
  margin-left: 0.4rem;
}
.info.studies{
  font-size: 1.3rem;
  line-height: 2rem;
  margin-top: 2rem;
}
.info.single.studies{
  width: 30%;
}

.points>li{
  font-size: 1.3rem;
  line-height: 2rem;
}

.stickyTop {
  position: sticky;
  top: 150px;
  overflow: visible;
  width: fit-content;
  margin-bottom: -15vw;
  padding-left: 3rem;
}

.navList {
  transition: all 0.4s ease;
  padding-block: 1rem;
}

.navLink {
  padding-block: 1rem;
  transition: all 0.4s ease;
}

.navList:hover {
  color: white;
  position: relative;
  margin-left: 2rem;
}

.navLink.active {
  transition: all 0.4s ease;
  color: white;
  margin-left: 2rem;
  position: relative;
}

.navLink.active::before {
  content: '';
  position: absolute;
  left: -2.4rem;
  top: 25px;
  width: 30px;
  height: 2px;
  background-color: white;
  border-radius: 40px;
}

.nav {
  padding-left: 0;
  margin-top: 0rem;
  color: #bcbcbc;
}

.sectionHeading {
  padding-top: 5vw;
  font-size: 3rem;
  font-weight: 700;
  width: 80%;
  margin-bottom: 3rem;
}
#overview{
  padding-top: 0;

}

.solution {
  margin-top: 3rem;
}

/* ChatBot */
#chatBot {
  width: 55%;
  color: #333;
  background: #121212;
  overflow: hidden;
  border-radius: 20px;
  padding-top: 7rem;
}
.chatText>div>svg{
  width: 50px !important;
  height: 15px !important;
  scale: 10 !important;
}

.sendButton {
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  z-index: 3;
  top: 14px;
  right: 9px;
}

.sendButton path {
  transform: translate(-1px, -1px) scale(0.7);
}

.navList a:hover {
  text-decoration: none;
}

.chatName {
  color: #807f7f;
  margin-bottom: 0.2rem;
}

.awayText {
  padding: 0.8rem;
  margin: 0.2rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 500;
}

.ownText {
  padding: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.chatName {
  font-weight: 500;
}

.chatText {
  background-color: #e9e9eb;
  width: fit-content;
  color: #000;
  border-radius: 20px;
  padding: 0.8rem;
}

.chatText.own {
  color: #000;
  background-color: #EEE3D3;
  max-width: 80%;
}

.lowerBox {
  display: flex;
  margin-inline: 1rem;
  margin-block: 0.6rem;
}

.logo {
  width: 2rem;
  cursor: pointer;
  margin-inline: 0.4rem;
}

.messageForm {
  width: -webkit-fill-available;
}

.messageContainer input {
  color: white;
  background-color: #000;
  border: 2.01838px solid rgba(84, 84, 88, 0.65);
  border-radius: 61.835px;
  margin-top: 0.5rem;
}

.upperBox {
  overflow: hidden;
  height: 17rem;
}

.textContainer {
  transition: all 0.4s ease;
}

#subject,
#phone,
#email {
  display: none;
  opacity: 0;
}


/* Case Study Section */

.studyCont {
  background-color: #0D0D0D;
  padding-block: 3rem;
  margin-top: 5rem;
}

.wideStudy {
  background: #121212;
  color: #EEE3D3;
  cursor: pointer;
  overflow: hidden;
  border-radius: 28px;
  transition: all 0.2s ease-in;
}

.wideStudy:hover {
  /* background: #383838; */
  background: #222222;
  box-shadow: rgb(45, 45, 45, 0.4) 0px 0px 16px 10px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 10px 0px 25px rgba(95, 95, 95, 0.14); */
  scale: 1.05;
}
.wideStudy.carousel:hover{
  scale: 1 !important;
}

.caseHeading {
  font-size: 1.6rem;
  font-weight: 600;
}

.caseText {
  font-size: 1.3rem;
}

.studyText {
  margin: 2rem;
}

.redirect {
  background: #EEE3D3 !important;
  border-radius: 12px !important;
  font-size: 1.15rem !important;
  font-weight: 500 !important;
  margin-top: 2rem !important;
  color: #000;
}

.featureHeading{
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: 0.4rem;
}
.featureInfo{
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
}




/* Case Study Carousel */

.carousel-root {
  height: 100%;
}

.slide,
.carousel,
.slider,
.slider-wrapper {
  height: 100% !important;
}

.slide {
  overflow: hidden;
  border-radius: 28px;
}

.footerFlex {
  width: 15%;
  justify-content: space-around !important;
  margin-inline: auto;
}

.footerFlex svg {
  cursor: pointer;
}




/* @media (max-width:1400px) {
  .testAndChat{
    flex-direction: column;
  }
} */

/* Loader */

.wrapper {
  min-width: 100vw;
  /* min-height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  transition: 0.5s;
  cursor: none !important;
}

.wrapper video {
  width: 13%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: none !important;
}

/* Loader */

.loaderWrapper{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #121212;
  z-index: 100;
  transition: all 1s ease-out;
  justify-content: center;
  align-items: center;
  color: #EEE3D3;
}

.loaderWrapper>div{
  font-size: 5rem;
  transform: translateY(40%);
  display: none;
  opacity: 0;
}


/* Responsiveness */

@media screen and (max-width: 1400px) {
  .slick-dots{
    right: -130px !important;
  }
}
@media screen and (max-width: 1100px) {
  .slick-dots{
    right: -80px !important;
  }
}

@media screen and (max-width: 800px) {
  .motionContainer{
    width: 45%;
  }
  .subHeading{
    width: 70%;
    height: 6rem;
  }
  .slick-dots{
    right: -180px !important;
  }
  .heading{
    font-size: 3rem;
  }
  .testAndChat{
    flex-direction: column;
  }
  #chatBot{
    width: 100%;
    padding-top: 4rem;
    margin-top: 2rem;
  }
  .testimonial{
    width: 100%;
  }
}

@media screen and (max-width: 450px){
  .heading{
    font-size: 2rem;
    margin-inline: 0.8rem;
    text-align: center;
  }
  .navbar{
    width: 90%;
    padding-top: 1.25rem;
  }
  .caseInfo{
    padding-bottom: 0rem;
  }
  .subHeading{
    width: 80%;
    font-size: 1.2rem;
  }
  .heroCarousel{
    width: max-content;
    margin-top: 4rem !important;
  }
  .greetText{
    font-size: 0.8rem;
  }
  .caseInfo{
    padding-left: 1.5rem;
  }
  .studyText{
    width: 90% !important;
  }
  .caseStudyFlex{
    flex-direction: column;
  }
  .caseImageContainer{
    margin-right: 0 !important;
    justify-content: flex-end;
  }
  .caseImageContainer>img{
    width: 70%;
    height: 8rem;
    object-fit: cover;
    object-position: 0rem -1.4rem;
  }
  .studyHeader{
    flex-direction: column;
  }
  .testAndChat{
    flex-direction: column;
  }
  .ownText{
    padding: 0.5rem;
  }
  .caseHead{
    font-size: 2rem;
    padding-left:1.5rem;
    padding-top: 5rem;
  }
  .awayText{
    padding: 0.5rem;
  }
  .slick-dots{
    bottom: 20px !important;
    left: -75px !important;
  }
  .chatText{
    padding: 0.75rem;
  }
  .footerFlex{
    width: 50%;
  }
  .lottieWrapper{
    flex-direction: column;
    width: 85% !important;
  }
  .motionContainer{
    width: 100%;
  }
  .videoContainer{
    width: 100%;
  }
  .bigVideoCont video{
    width: 100%;
  }
  .stickyTop{
    display: none;
  }
  .aboutVision{
    flex-direction: column;
  }
  .about{
    width: fit-content;
  }
  .info.single.studies{
    width: 100%;
  }
  .roundVideo{
    width: 100%;
    border-radius: 20px;
  }
  .featureFlex{
    flex-direction: column-reverse;
  }
  .featureFlexReverse{
    flex-direction: column;
  }
  .featureContent{
    margin-left: 1rem;
  }
  #research{
    font-size: 2rem;
    width: 100%;
  }
  .about img{
    width: 100%;
  }
  .studyCont>div{
    width: 90%;
  }
  .testAndChat{
    width: 90%;
  }
}